<template>
  <div>
    <ModalAjoutMvtStock id="modalAjoutArticleInventaire" ref="modalAjoutArticleInventaire" :disabled="!inventaireSelectionneNonValide" title="Ajouter un article à l'inventaire" action="in" mode="inventaire" @envoyer-article="ajouterArticle($event)" />
    <!--<ImportInventaire ref="modalImportInventaire" :disabled="!dateCreationValide" :date="dateInventaireACreer" :inventaireId="inventaire ? inventaire.id : null" @importer="importerStockInventaire($event)"/>-->

    <div class="container-fluid">
      <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" :key="'filtres' + keyFiltres" />
      <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

      <div v-if="!inventaire" class="card">
        <div class="card-body">
          Pour commencer un nouvel inventaire à partir du stock à la date correspondante, merci de sélectionner une date et de cliquer sur le bouton.
        </div>
      </div>

      <!--<div v-if="inventaire && !inventaire.valide" class="alert alert-warning mb-2" role="alert">
        <strong>Inventaire en cours.</strong> Les fonctionnalités suivantes sont désactivées afin de ne pas fausser le stock.<br />
        Mouvements de stock, réception de commande, sortie de stock d'une production.
      </div>-->

      <div v-if="inventaire" class="card table-scroll height-responsive-100">
        <div class="card-header flex-center-space-between">
          <div class="flex-start">
            <span>{{ inventaire && inventaire.date && formatHumanDate(new Date(inventaire.date)) }}</span>
            <span v-if="inventaireSelectionneNonValide" class="text-warning ml-5"><i class="fas fa-exclamation-triangle text-warning"></i> Inventaire en cours : Les mouvements de stock et les réceptions de commandes sont désactivés afin de ne pas fausser le stock</span>
          </div>
          <div class="flex-end">
            <span class="ml-5">Total HT : {{ valeurTotaleInventaire(inventaire) | afficherPrix2 }}</span>
            <span class="ml-5">Total TTC : {{ valeurTTCTotaleInventaire(inventaire) | afficherPrix2 }}</span>
          </div>
        </div>
        <table class="table table-bordered table-sm text-center table-header-secondary">
          <colgroup>
            <col width="120">
            <col>
            <col width="120">
            <col width="120">
            <col v-if="inventaireSelectionneNonValide" width="120">
            <col width="120">
            <col width="120">
            <col v-if="inventaireSelectionneNonValide" width="55">
          </colgroup>
          <thead>
            <th>Référence</th>
            <th class="aligner-gauche">Désignation</th>
            <th>N° Lot</th>
            <th>DLC/DDM</th>
            <th v-if="inventaireSelectionneNonValide">Stock</th>
            <th>Quantité</th>
            <th>Total HT</th>
            <th v-if="inventaireSelectionneNonValide">Vérifié</th>
          </thead>
        </table>
        <div class="table-body">
          <table class="table table-sm table-bordered text-center">
            <colgroup>
              <col width="120">
              <col>
              <col width="120">
              <col width="120">
              <col v-if="inventaireSelectionneNonValide" width="120">
              <col width="120">
              <col :width="inventaireSelectionneNonValide ? 120 : 115">
              <col v-if="inventaireSelectionneNonValide" width="50">
            </colgroup>
            <tbody>
              <template v-for="(zone, iZone) in inventaire.zonesStockage">
                <tr v-if="!filtreZonesDeStockage.length || filtreZonesDeStockage.includes(zone.designation)" class="table-header-tertiary" style="position:sticky;top:0;z-index:5" :key="'zone' + iZone">
                  <td :colspan="inventaireSelectionneNonValide ? 8 : 6">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-8 flex-start">{{ zone.designation.toUpperCase() }}</div>
                        <div class="col-2 flex-end">
                          {{ valeurTotaleZoneStockage(zone) | afficherPrix }}
                        </div>

                        <div class="col-2 aligner-droite">
                          <label v-if="inventaireSelectionneNonValide" class="label-checkbox pt-1">
                            <span class="ml-2 pt-1">Vérifié </span>
                            <ToggleButton class="ml-2" v-model="zone.verifiee" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="12" :width="20" :key="keyToggle" @change="verifierQtesLots(zone)" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <template v-if="!filtreZonesDeStockage.length || filtreZonesDeStockage.includes(zone.designation)">
                  <template v-for="(lot, iLot) in obtenirLots(zone)">
                    <tr v-if="formaterRecherche(lot.designationFournisseur).includes(formaterRecherche(filtreNomProduit)) || rechercheIngredient(lot, formaterRecherche(filtreNomProduit))" :key="'zone' + iZone + 'lot' + iLot">
                      <td style="vertical-align: middle">
                        {{lot.reference}}
                      </td>
                      <td class="aligner-gauche" style="vertical-align: middle">
                        <span>{{lot.designationFournisseur}}</span>
                      </td>
                      <td style="vertical-align: middle;max-width:50px">
                        <span>{{lot.NumLot}}</span>
                      </td>
                      <td style="vertical-align: middle;max-width:50px">
                        <span>{{formatHumanDate(lot.dlcDluo)}}</span>
                      </td>
                      <td v-if="inventaireSelectionneNonValide" style="vertical-align: middle;max-width:50px">
                        {{obtenirQteLotEnStock(lot.id)}} {{lot.uc}}
                      </td>
                      <td style="vertical-align: middle;max-width:50px">
                        <span v-if="inventaireSelectionneNonValide">
                          <InputNumber :id="'lotQte' + lot.id" :zeroSiVide="true" v-model="lot.quantite"  style="width:100%" typeFiltre="quantite" placeholder="Quantité" @input="setQuantiteLot(zone, lot, $event)" @move="moveLot($event)" />
                        </span>
                        <span v-else>
                          {{lot.quantite}} {{lot.uc}}
                        </span>
                      </td>
                      <td style="vertical-align: middle">
                        {{(lot.quantite * lot.valeurUnitaire) | afficherPrix}}
                      </td>
                      <td v-if="inventaireSelectionneNonValide" style="vertical-align: middle">
                        <ToggleButton class="mt-1" v-model="lot.quantiteVerifiee" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="12" :width="20" :key="keyToggle" @change="QteLotEstVerifiee(zone)" />
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import ModalAjoutMvtStock from '@/components/Stock/ModalAjoutMvtStock'
import InputNumber from '@/components/Inputs/InputNumber'
//import ImportInventaire from '@/components/Stock/ImportInventaire.vue'
import { obtenirStock, listeInventaires, detailInventaire, creerInventaire, modifierInventaire, validerInventaire, annulerInventaire } from '@/api/gpao/stock'
import { obtenirProduitMinimum } from '@/api/gpao/produits'
import { connecteData } from '@/mixins/connecteData'
import { showAlerts } from '@/mixins/alerts'
import { formatageDate } from '@/mixins/dateFormats'
import { exporterInventaire } from '@/api/gpao/documents'
import { lancerTelechargementDeResponse, formaterPourRecherche } from "@/helpers/utils"
import { designationZoneStockage } from "@/helpers/familles"
import { alerteDonneesModifiees } from '@/mixins/index'
import _ from 'lodash'

export default {
  name: "PageInventaire",
  components: {
    BarreFiltres,
    BarreActions,
    ModalAjoutMvtStock,
    InputNumber,
//    ImportInventaire,
  },
  mixins: [connecteData, showAlerts, formatageDate, alerteDonneesModifiees],
  data() {
    return {
      inventaires: [],
      inventaire: null,
      stock: null,
      dateInventaireACreer: '',
      enExportation: false,
      calendrierKey: 0,
      enCalcul: false,
      filtreZonesDeStockage: [],
      filtreNomProduit: '',
      keyToggle: 1,
      keyFiltres: 1,
      lignesStock: [],
      filtres: {
        dateInventaire: {
          label: "Inventaire en date du ...",
          champ: "datepicker",
          //disabledDates: new Date(),
          availableDates: [],
          selection: null,
          initialiser(contexte) {
            let filtre = contexte.filtres.dateInventaire
            filtre.availableDates = []
            if (contexte.inventaires?.length) {
              contexte.inventaires.forEach(i => filtre.availableDates.push(new Date(i.date)))
              // Si aucun inventaire n'est en cours de saisie, toutes les dates depuis le dernier inventaire et aujourd'hui peuvent être sélectionnées pour créer un nouvel inventaire
              if (contexte.tousLesInventairesValides) {
                let date = new Date(contexte.inventaires.map(i => i.date).reduce((a, b) => a > b ? a : b))
                let hier = new Date()
                hier.setDate(hier.getDate() - 1)
                while (date < hier) {
                  date.setDate(date.getDate() + 1)
                  filtre.availableDates.push(new Date(date))
                }
              }
            }
            filtre.selection = contexte.inventaire?.date || contexte.inventaires?.find(i => !i.valide)?.date || contexte.dateInventaireACreer || new Date()
          },
          async filtrer(contexte) {
            let filtre = contexte.filtres.dateInventaire
            if (filtre.selection) {
              if (contexte.inventaire && contexte.donneesModifiees) {
                let result = await contexte.alerteConfirmationEnregistrement()
                if (result) {
                  // Enregistrement des modifications
                  await contexte.enregistrerDonneesModifiees()
                }
              }

              contexte.selectionnerDate(filtre.selection)
            }
          }
        },
        zonesStockage: {
          label: "Zones de stockage",
          champ: "select",
          type: "multi",
          options: [],
          selection: [],
          initialiser(contexte) {
            let filtre = contexte.filtres.zonesStockage
            filtre.options = contexte.inventaire?.zonesStockage?.map(zs => zs.designation) || []
            filtre.selection = contexte.filtreZonesDeStockage
          },
          filtrer(contexte) {
            contexte.filtreZonesDeStockage = contexte.filtres.zonesStockage.selection || []
          }
        },
        nomLot: {
          label: "Recherche ingrédient",
          champ: "input",
          type: "text",
          selection: "",
          initialiser(contexte) {
            contexte.filtres.nomLot.selection = contexte.filtreNomProduit
          },
          filtrer(contexte) {
            contexte.filtreNomProduit = contexte.filtres.nomLot.selection
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.reinitialiser()
          contexte.filtres.barreGauche = [ contexte.filtres.dateInventaire, contexte.filtres.zonesStockage, contexte.filtres.nomLot ]
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          contexte.keyFiltres += 1
        },
        filtrer(contexte, commande) {
          return !contexte.filtres.barreFiltres.find(f => !f.filtrer(contexte, commande))
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        creerInventaire: {
          label: "Créer un nouvel inventaire",
          disabled: false,
          methode(contexte) {
            contexte.creerStockInventaire()
          }
        },
        ajouterArticle: {
          label: "Ajouter un article",
          methode(contexte) {
            contexte.afficherAjoutMvtStock()
          }
        },
        exporter: {
          label: "Exporter l'inventaire",
          methode(contexte) {
            contexte.exporter(contexte.inventaire)
          }
        },
        enregistrerModifications: {
          label: "Enregistrer les modifications",
          class: "btn-success",
          disabled: false,
          initialiser(contexte) {
            contexte.actions.enregistrerModifications.disabled = !contexte.inventaireSelectionneNonValide || !contexte.donneesModifiees
          },
          methode(contexte) {
            contexte.enregistrerInventaire(contexte.inventaire.id, contexte.lignesInventaire)
          }
        },
        annulerModifications: {
          label: "Annuler les modifications",
          class: "btn-danger",
          disabled: false,
          initialiser(contexte) {
            contexte.actions.annulerModifications.disabled = !contexte.inventaireSelectionneNonValide || !contexte.donneesModifiees
          },
          async methode(contexte) {
            let result = await contexte.alerteConfirmation("Annuler les modifications", "Vos modifications seront définitivement perdues.<br/>Souhaitez-vous annuler vos modifications ?")
            if (result) {
              contexte.selectionnerDate(contexte.inventaire.date)
            }
          }
        },
        validerInventaire: {
          label: "Valider l'inventaire",
          class: "btn-success",
          /*initialiser(contexte) {
            contexte.actions.validerInventaire.disabled = !contexte.inventaireSelectionneNonValide || contexte.donneesModifiees
          },*/
          methode(contexte) {
            contexte.validerInventaire(contexte.inventaire.id)
          }
        },
        annulerInventaire: {
          label: "Annuler l'inventaire",
          class: "btn-danger",
          /*initialiser(contexte) {
            contexte.actions.annulerInventaire.disabled = !contexte.inventaireSelectionneNonValide
          },*/
          methode(contexte) {
            contexte.annulerInventaire(contexte.inventaire.id)
          }
        },
        /*creerInventaireExcel: {
          label: "Créer un inventaire à partir d'un fichier Excel",
          methode(contexte) {
            contexte.$refs.modalImportInventaire.show()
          }
        },*/
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.creerInventaire, contexte.actions.ajouterArticle, contexte.actions.exporter, contexte.actions.annulerModifications, contexte.actions.enregistrerModifications ]
          contexte.actions.barreDroite = [ contexte.actions.annulerInventaire, contexte.actions.validerInventaire ]
          contexte.actions.barreGauche.forEach(a => {
            if (a.initialiser) {
              a.initialiser(contexte)
            }
          })
          contexte.actions.barreDroite.forEach(a => {
            if (a.initialiser) {
              a.initialiser(contexte)
            }
          })
          contexte.actions.initialiserAccesActions(contexte)
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disableEnregistrement(actions, disabled) {
          actions.enregistrerModifications.disabled = disabled
          actions.annulerModifications.disabled = disabled

          actions.exporter.disabled = !disabled
          actions.validerInventaire.disabled = !disabled
          actions.annulerInventaire.disabled = !disabled
        },
        initialiserAccesActions(contexte) {
          let actionsATraiter = [
            contexte.actions.ajouterArticle,
            contexte.actions.enregistrerModifications,
            contexte.actions.annulerModifications,
            contexte.actions.validerInventaire,
            contexte.actions.annulerInventaire
          ]
          actionsATraiter.forEach(a => a.hidden = !contexte.inventaire ? true : contexte.inventaire.valide ? true : false)
          contexte.actions.exporter.hidden = contexte.inventaire ? false : true
          contexte.actions.creerInventaire.disabled = !contexte.tousLesInventairesValides || contexte.inventaire != null
        }
      }
    }
  },
  computed: {
    tousLesInventairesValides() {
      return !this.inventaires.some(el => el.valide === false)
    },
    inventairesValides() {
      return this.inventaires.filter(el => el.valide === true)
    },
    inventaireSelectionneNonValide() {
      return this.inventaire && !this.inventaire.valide
    },
    dateCreationValide() {
      if (!this.dateInventaireACreer || !this.tousLesInventairesValides) {
        return false
      }
      if (this.inventaires.length === 0) {
        return true
      }
      return (this.formatDateAvecTirets(this.dateInventaireACreer) > this.formatDateAvecTirets(new Date(Math.max.apply(null, this.inventaires.map(el => new Date(el.date))))))
    },
    lignesInventaire() {
      return this.obtenirLignesInventaire(this.inventaire)
    },
    donneesModifiees() {
      if (!this.donneesInitiales || !this.donneesInitiales.lignes)
        return false
      if (this.donneesInitiales.lignes.length !== this.lignesInventaire.lignes.length)
        return true

      var modifie = false
      this.donneesInitiales.lignes.forEach(el => {
        let li = this.lignesInventaire.lignes.find(l => l.id === el.id)
        if (!li)
          modifie = true
        if (li.reference !== el.reference || li.designationFournisseur !== el.designationFournisseur || li.NumLot !== el.NumLot || li.dlc !== el.dlc
          || this.formatDateAvecTirets(li.dlcDluo) !== this.formatDateAvecTirets(el.dlcDluo) || li.quantite !== el.quantite
          || li.valeurUnitaire !== el.valeurUnitaire || li.tarifId !== el.tarifId || li.quantiteVerifiee !== el.quantiteVerifiee)
          modifie = true
      })
      return modifie
    }
  },
  watch: {
    donneesModifiees() {
      this.actions.disableEnregistrement(this.actions, !this.donneesModifiees)
    }
  },
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'action
      methode(this)
    },
    async enregistrerDonneesModifiees() {
      await this.enregistrerInventaire(this.inventaire.id, this.lignesInventaire)
    },
    reinitialiser() {
      this.filtreZonesDeStockage = []
      this.filtreNomProduit = ""
    },
    moveLot(event) {
      var lotId = Number(event.id.replace("lotQte", ""))
      var index = this.lignesInventaire.lignes.findIndex(x => x.id === lotId)
      if (event.direction === "up") {
        if (index === 0) {
          index = this.lignesInventaire.lignes.length
        }
        lotId = this.lignesInventaire.lignes[index - 1].id
      } else if (event.direction === "down" || event.direction === "enter") {
        if (index === this.lignesInventaire.lignes.length - 1) {
          index = -1
        }
        lotId = this.lignesInventaire.lignes[index + 1].id
      }
      document.getElementById("lotQte" + lotId)?.focus()
    },
    setQuantiteLot(zone, lot, quantite) {
      lot.quantite = quantite
      if (!lot.quantiteVerifiee)
        lot.quantiteVerifiee = true
      this.QteLotEstVerifiee(zone)
      ++this.keyToggle
    },
    verifierQtesLots(zone) {
      zone.ingredients.forEach(x => x.lots.forEach(y => {
        if (!y.quantiteVerifiee)
          y.quantiteVerifiee = true
      }))
      zone.verifiee = true
      ++this.keyToggle
    },
    QteLotEstVerifiee(zone) {
      if (zone.ingredients.find(x => x.lots.find(y => !y.quantiteVerifiee))) {
        zone.verifiee = false
      } else {
        zone.verifiee = true
      }
      ++this.keyToggle
    },
    setdateInventaireACreer(event) {
      this.dateInventaireACreer = event
    },
    setDlcDluo(event, elt) {
      this.$set(elt, 'dlcDluo', event)
    },
    async setInventaire(id) {
      let response = await detailInventaire(id)
      response.data.zonesStockage.forEach(zone => {
        zone.tousIngredients = _.cloneDeep(zone.ingredients)
        this.QteLotEstVerifiee(zone)
      })
      this.inventaire = response.data
      this.donneesInitiales = _.cloneDeep(this.lignesInventaire)
    },
    getStock(date) {
      return obtenirStock(this.etabCourantId, date, "")
        .then((response) => {
          this.stock = response.data
          this.lignesStock = this.obtenirLotsInventaire(this.stock)
          return
        })
    },
    obtenirLots(zoneStockage) {
      if (!zoneStockage?.ingredients?.length)
        return []
      let lots = []
      zoneStockage.ingredients.forEach(ingredient => {
        ingredient.lots.forEach(l => {l.rechercheIngredient = ingredient.rechercheIngredient})
        lots = lots.concat(ingredient.lots)
      })
      return lots
    },
    formaterRecherche(texte) {
      return formaterPourRecherche(texte)
    },
    rechercheIngredient(lot, texte) {
      let s = lot.rechercheIngredient.filter(x => x.includes(texte));
      return s.length > 0
    },
    valeurTotaleZoneStockage(zoneStockage) {
      return this.obtenirLots(zoneStockage).reduce((s, v) => s + v.valeur, 0)
    },
    valeurTTCTotaleZoneStockage(zoneStockage) {
      return this.obtenirLots(zoneStockage).reduce((s, v) => s + v.valeurTTC, 0)
    },
    obtenirLignesInventaire(inventaire) {
      if (!inventaire)
        return { lignes: [] }
      return { lignes: this.obtenirLotsInventaire(inventaire) }
    },
    obtenirLotsInventaire(inventaire) {
      if (!inventaire?.zonesStockage?.length)
        return []
      return inventaire.zonesStockage.map(z => this.obtenirLots(z)).reduce(function (a, b) { return a.concat(b) })
    },
    valeurTotaleInventaire(inventaire) {
      return this.obtenirLotsInventaire(inventaire).reduce((s, v) => s + (v.valeur), 0)
    },
    valeurTTCTotaleInventaire(inventaire) {
      return this.obtenirLotsInventaire(inventaire).reduce((s, v) => s + (v.valeurTTC), 0)
    },
    obtenirQteLotEnStock(lotId) {
      let lot = this.lignesStock.find(l => l.id === lotId)
      if (!lot)
        return 0
      return lot.quantite
    },
    afficherAjoutMvtStock() {
      this.$refs.modalAjoutArticleInventaire.show()
    },
    ajouterArticle(elt) {
      return obtenirProduitMinimum(elt.article.produitId)
        .then((response) => {
          // Recherche de la zone de stockage
          let famille = this.$store.state.definitions.familles.find(f => f.id === response.data.familleId)
          let dzs = designationZoneStockage(famille.zoneStockage)
          let zs = this.inventaire.zonesStockage.find(z => z.designation === dzs)
          if (!zs) {
            zs = { designation: dzs, valeur: 0, ingredients: [] }
            this.inventaire.zonesStockage.push(zs)
          }
          // Contrôle de doublons
          let lotsExistants = this.obtenirLotsInventaire(this.inventaire)
          let lotExistant = lotsExistants.find(x => (x.reference ?? x.designationFournisseur) === (elt.article.reference ?? elt.article.designationFournisseur)
            && (x.NumLot ?? "") === (elt.article.NumLot ?? "") && this.formatDateAvecTirets(x.dlcDluo) === this.formatDateAvecTirets(elt.article.dlcDluo))
          if (lotExistant) {
            // eslint-disable-next-line promise/no-nesting
            return this.alerteConfirmation("Lot déjà présent dans l'inventaire",
              "Un lot identique a été détecté dans l'inventaire (quantité : " +
              lotExistant.quantite + lotExistant.uc + "). Voulez-vous affecter la quantité que vous avez saisie (" + elt.article.quantite + elt.article.uc + ") ?")
            .then((result) => {
              if (result) {
                this.setQuantiteLot(zs, lotExistant, elt.article.quantite)
              }
              return
            })
          }
          elt.article.quantiteVerifiee = true
          if (!elt.article.designationFournisseur)
            elt.article.designationFournisseur = elt.article.designation || response.data.designation
          zs.ingredients.push({ lots: [elt.article] })
          zs.valeur += elt.article.quantite * elt.article.valeurUnitaire
          return
        })
    },
    async enregistrerInventaire(id, lignes) {
      lignes.lignes = lignes.lignes.filter(x => x.quantite !== 0)
      await modifierInventaire(id, lignes)
      this.alerteSucces('Modification enregistrée !')
      await this.selectionnerDate(this.inventaire.date)
      this.donneesInitiales = _.cloneDeep(this.lignesInventaire)
    },
    async validerInventaire(id) {
      let result = await this.alerteConfirmation("Clôturer l'inventaire", "Souhaitez-vous clôturer l'inventaire en cours ? Il ne sera plus modifiable ensuite, et le stock sera mis à jour.")
      if (result) {
        await validerInventaire(id)
        this.alerteSucces("Inventaire validé !")
        this.listerInventaires(this.etabCourantId)
        this.selectionnerDate(this.inventaire.date)
      }
    },
    async annulerInventaire(id) {
      var result = await this.alerteConfirmation("Annuler l'inventaire", "Souhaitez-vous annuler l'inventaire en cours ? Cette action supprime l'inventaire définitivement, et le stock n'est pas mis à jour.")
      if (result) {
        await annulerInventaire(id)
        this.listerInventaires(this.etabCourantId)
        this.inventaire = {}
        this.donneesInitiales = {}
      }
    },
    async creerStockInventaire() {
      if (!this.dateCreationValide) {
        return false;
      }
      try {
        this.actions.creerInventaire.disabled = true
        await creerInventaire(this.etabCourantId, this.formatDateAvecTirets(this.dateInventaireACreer))
        this.alerteSucces('Inventaire créé !')
        this.listerInventaires(this.etabCourantId)
      } catch(error) {
        this.actions.creerInventaire.disabled = false
        this.listerInventaires(this.etabCourantId)
      }
    },
    obtenirInventaireADate(inventaires, date) {
      return inventaires.find(el => this.formatDateAvecTirets(new Date(el.date)) === this.formatDateAvecTirets(new Date(date)))
    },
    async selectionnerDate(date) {
      this.setdateInventaireACreer(date)
      let inv = this.obtenirInventaireADate(this.inventaires, date)
      if (inv) {
        console.time("setInventaire")
        if (!inv.valide) {
          this.getStock(date)
        }
        await this.setInventaire(inv.id)
        console.timeEnd("setInventaire")
      } else {
        this.inventaire = null
      }
      this.filtres.initialiser(this)
      this.actions.initialiser(this)
      //this.actions.afficherActionsSurChangementInventaire(this, this.inventaire?.valide || true)
    },
    async listerInventaires(etablissementId) {
      let response = await listeInventaires(etablissementId)
      _.remove(response.data, el => el.date === '0001-01-01T00:00:00')
      this.inventaires = response.data
      let inventaire = this.inventaires.find(inventaire => inventaire.valide === false)
      await this.selectionnerDate(inventaire ? inventaire.date : new Date())
      //this.filtres.initialiser(this)
      //this.actions.initialiser(this)
    },
    async exporter(inventaire) {
      try {
        this.enExportation = true
        const response = await exporterInventaire(inventaire.id)
        lancerTelechargementDeResponse(response)
      } finally {
        this.enExportation = false
      }
    }
  },
  created() {
    // L'initialisation des barres de filtres et d'actions est faite dans la méthode listerInventaires ci-dessous
    this.listerInventaires(this.etabCourantId)
  }
}
</script>
